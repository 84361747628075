<template>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
          </div>
        </ion-col>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
            <img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/brands%2Fthumbnails%2Fcomenza_1080x1080.webp?alt=media" alt="Marvel">
          </div>
        </ion-col>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
            <img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/brands%2Fthumbnails%2Fjnf_1080x1080.webp?alt=media" alt="DC">
          </div>
        </ion-col>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
            <img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/brands%2Fthumbnails%2Fpattex_1080x1080.webp?alt=media" alt="Disney">
          </div>
        </ion-col>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
            <img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/brands%2Fthumbnails%2Fatusa_1080x1080.webp?alt=media" alt="Star Wars">
          </div>
        </ion-col>
        <ion-col size="6" size-xl="2">
          <div class="box brand">
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Brands',
  components: {},
});
</script>

<style scoped>
  .brand{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .brand img{
    width: auto;
    height: 64px;
  }
</style>