
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import {arrowForwardOutline } from 'ionicons/icons';

// Components
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

// Modules
  // Shop
  import MainSlider from '@/modules/shop/MainSlider.vue';
  import Features from '@/modules/shop/Features.vue';
  import Offers from '@/modules/shop/Offers.vue';
  import NewProducts from '@/modules/shop/NewProducts.vue';
  import Testimonials from '@/modules/shop/Testimonials.vue';
    import Brands from '@/modules/shop/Brands.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    MainSlider,
    Features,
    //Offers,
    NewProducts,
    Testimonials,
    Brands
  },
  setup() {
    return {
      arrowForwardOutline,
    }
  }
});
