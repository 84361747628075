<template>
  <div class="container">
    <h2 class="section-title"><strong>Novedades</strong></h2>
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-xl="3" v-for="product in newProducts" :key="product.id">
          <Product :data="product"></Product>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {getNewProducts} from '@/store/firestoreActions';
import Product from '@/components/Product.vue';

export default defineComponent({
  name: 'NewProduct',
  components: {Product},
  data() {
    return {
      newProducts: [0,0,0,0,0,0,0,0]
    }
  },
  beforeMount(){
    getNewProducts().then((res: any) => {
       this.newProducts = res;
    });
  }
});
</script>

<style scoped>

</style>