
import { defineComponent } from 'vue';
import { IonIcon } from '@ionic/vue';
import { cubeOutline, shieldCheckmarkOutline, settingsOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Features',
  components: {
    IonIcon
  },
  setup() {
    return {
      cubeOutline,
      shieldCheckmarkOutline,
      settingsOutline
    }
  }
});
