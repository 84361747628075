
import { defineComponent } from 'vue';
import {getNewProducts} from '@/store/firestoreActions';
import Product from '@/components/Product.vue';

export default defineComponent({
  name: 'NewProduct',
  components: {Product},
  data() {
    return {
      newProducts: [0,0,0,0,0,0,0,0]
    }
  },
  beforeMount(){
    getNewProducts().then((res: any) => {
       this.newProducts = res;
    });
  }
});
