<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

<section id="main-slider">
        <MainSlider></MainSlider>
      </section>

      <section class="md-section" id="features">
        <Features></Features>
      </section>

      <!--<section class="md-section" id="offers">
        <Offers></Offers>
      </section>-->
      
      <section class="md-section" id="new-products">
        <NewProducts></NewProducts>
      </section>

      <section id="banners" class="bg-light2 lg-section" style="margin: 2rem 0">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <div class="full-banner" style="background: linear-gradient(0deg, rgba(0,0,0, 0.5), rgba(0,0,0,0)), url('https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/home-categories%2Fthumbnails%2Fmobiliario-playa_1080x1080.webp?alt=media')">
                  <div class="banner-content text-light">
                    <h4>Mobiliario de Playa</h4>
                    <ion-button color="secondary" shape="round" href="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/CATALOGO_NOVOINOX_2021.pdf?alt=media" target="_blank">
                      Descargar Catálogo
                      <ion-icon slot="end" color="light" :icon="arrowForwardOutline"></ion-icon>
                    </ion-button>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <section class="md-section" id="testimonials">
        <Testimonials></Testimonials>
      </section>

      <section class="sm-section" id="Brands">
        <Brands></Brands>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import {arrowForwardOutline } from 'ionicons/icons';

// Components
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

// Modules
  // Shop
  import MainSlider from '@/modules/shop/MainSlider.vue';
  import Features from '@/modules/shop/Features.vue';
  import Offers from '@/modules/shop/Offers.vue';
  import NewProducts from '@/modules/shop/NewProducts.vue';
  import Testimonials from '@/modules/shop/Testimonials.vue';
    import Brands from '@/modules/shop/Brands.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    MainSlider,
    Features,
    //Offers,
    NewProducts,
    Testimonials,
    Brands
  },
  setup() {
    return {
      arrowForwardOutline,
    }
  }
});
</script>

<style scoped>
  .mid-banner{
    padding: 128px;
    margin: 0.5rem;

    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    overflow: hidden;
  }

  .full-banner{
    padding: 128px;
    margin: 0.5rem;
    
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    overflow: hidden;
  }

  .banner-content{
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 2rem;
  }

  .banner-content h4{
    margin-bottom: 0.5rem !important;
  }
</style>