<template>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-lg="4">
          <div class="box bg-primary flex" style="margin-bottom: 0">
            <ion-icon class="feature-icon" :icon="cubeOutline"></ion-icon>
            <span>
              <span><strong>Envíos en 48h</strong></span>
              <p>Envíos a toda España</p>
            </span>
          </div>
        </ion-col>
        <ion-col size="12" size-lg="4">
          <div class="box bg-primary flex" style="margin-bottom: 0">
            <ion-icon class="feature-icon" :icon="shieldCheckmarkOutline"></ion-icon>
            <span>
              <span><strong>Pago Seguro</strong></span>
              <p>Pago gestionado por Stripe</p>
            </span>
          </div>
        </ion-col>
        <ion-col size="12" size-lg="4">
          <div class="box bg-primary flex" style="margin-bottom: 0">
            <ion-icon class="feature-icon" :icon="settingsOutline"></ion-icon>
            <span>
              <span><strong>Fabricación</strong></span>
              <p>Producimos piezas a media</p>
            </span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonIcon } from '@ionic/vue';
import { cubeOutline, shieldCheckmarkOutline, settingsOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Features',
  components: {
    IonIcon
  },
  setup() {
    return {
      cubeOutline,
      shieldCheckmarkOutline,
      settingsOutline
    }
  }
});
</script>

<style scoped>
  .feature-icon{
    font-size: 2.7rem;
    margin-right: 1rem;
  }
</style>