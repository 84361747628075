
import { defineComponent } from 'vue';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Pagination]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import StarRating from 'vue-star-rating'

export default defineComponent({
  name: 'Nuestros Clientes',
  components: {
    Swiper,
    SwiperSlide,
    StarRating
  },
});
