<template>
  <div class="container xs-section">
    <h2 class="section-title"><strong>Nuestros Clientes</strong></h2>
    <swiper class="testimonials-slider" :pagination="true" :slidesPerView="1">
        <swiper-slide class="slide">
          <div class="box testimonial bg-light2">
            <p>Superprofesionales. Una calidad muy alta. Personal muy agradable y servicial.</p>
            <star-rating :rating="5" :star-size="15" :read-only="true" :show-rating="false" class="rating"></star-rating>
            <h6 class="text-dark">Mara</h6>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div class="box testimonial bg-light2">
            <p>Tienen de todo en acero inoxidable. muy buena exposición. Self Service.</p>
            <star-rating :rating="5" :star-size="15" :read-only="true" :show-rating="false" class="rating"></star-rating>
            <h6 class="text-dark">Chimo Nebot Tabarca</h6>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div class="box testimonial bg-light2">
            <p>Profesionales como la copa de un pino y con unos precios super competitivos</p>
            <star-rating :rating="5" :star-size="15" :read-only="true" :show-rating="false" class="rating"></star-rating>
            <h6 class="text-dark">Fernando J. Ruiz</h6>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div class="box testimonial bg-light2">
            <p>Hay de todo correspondiente al acero inoxidable con respecto a náutica. Vi que hay para cualquier trabajo que tengas que realizar en bricolaje y profesional.</p>
            <star-rating :rating="5" :star-size="15" :read-only="true" :show-rating="false" class="rating"></star-rating>
            <h6 class="text-dark">Jose Or</h6>
          </div>
        </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Pagination]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import StarRating from 'vue-star-rating'

export default defineComponent({
  name: 'Nuestros Clientes',
  components: {
    Swiper,
    SwiperSlide,
    StarRating
  },
});
</script>

<style scoped>
  .testimonials-slider{
    padding-bottom: 1.5rem;
  }

  .testimonial{
    max-width: 960px;
    margin: 1rem;
  }

  .testimonial .rating{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  .testimonial h6{
    margin: 0.5rem 0 !important;
    text-transform: uppercase;
  }
</style>